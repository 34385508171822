import React from 'react';
import './Footer.scss';
import Logo from "../../assets/png/inmagic.png";
import What from "../../assets/png/whatsapp.png";

export default function Footer() {
    return (
        <div className="footerWrap">
            <footer className='contentinfo'>
           <div className="subscribeCTAWrapper">
           <h2>Ponte en contacto con nosotros</h2>
           <div className="subscribeCTADescription"> 
           ¿Tienes alguna pregunta o consulta? No dudes en enviarnos un mensaje a través de WhatsApp.
           </div>
           </div>
           <div className="copyRightRow">
            <div className="copyRightCol">
           <span> &copy; 2024 InMagic Corp. Todos los derechos reservados.</span>
            </div>
            <div className="logo">
                <img src={Logo} alt="" />
            </div>     
           </div>
        </footer>
        <div className="fixedButtonsWrap">
            <div className="fixedButtons">
            <a href='https://wa.me/+5215620130701?text=¡Hola! Me gustaría obtener más información.' target="_blank" className="button fixedButton">
    <img src={What} alt="WhatsApp" />
    <span>Solicitar información</span>   
</a>
            </div>
        </div>
        </div>
        
    )
}
