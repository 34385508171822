import React, { useState, useEffect } from 'react';
import {Container, Image} from 'react-bootstrap';
import {ReactComponent as Flecha} from "../../assets/svg/flecha.svg";
import Logo from "../../assets/png/inmagic.png";
import './Navbar.scss';


export default function Navbar({menuOpen ,setMenuOpen}) {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
      const handleScroll = () => {
          const currentPosition = window.scrollY;
          setScrollPosition(currentPosition);
          if (currentPosition > 110 && currentPosition > lastScrollTop && menuOpen === false) {
              // Scroll hacia abajo
              setLastScrollTop(currentPosition);
              document.querySelector('.navbar_top').style.top = '-110px';
          } else if (currentPosition > 110 && currentPosition < lastScrollTop) {
              // Scroll hacia arriba
              setLastScrollTop(currentPosition);
              document.querySelector('.navbar_top').style.top = '0';
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, [lastScrollTop, menuOpen]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Scroll suave
    });
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
};
    return (
      <Container className={`navbar_top ${scrollPosition > 0 ? 'scrolled' : ''}`} fluid>  
          <div  className="button_container" onClick={scrollToTop}>  
            <span>INICIO</span>
            <Flecha/>
          </div>
          <div className="logo">
           <Image src={Logo} alt=''/>  
          </div>
         <button className='menuTrigger' onClick={handleMenuToggle}>
          <span  className={`menuBar ${menuOpen ? 'active' : ''}`}></span>
          <span  className={`menuBar ${menuOpen ? 'active' : ''}`}></span>
         </button>
        </Container >
    )
}
