import React from 'react';
import './Includedleft.scss';
import Logo from "../../assets/png/inmagic.png";
 


const Includedleft = ({Image, Title, Descripcion}) => {
  return (
    <div className='includedItemleft'>
        <div className="includedItemHeadleft">
        <div className="itemLogo">
            <img src={Logo} alt="" />
          </div>
         <div className="itemHeadImageleft oddItem">
            <div className="itemHeadImageWrapleft">
                <img src={Image} alt="" />
            </div>
            <h3 className="includedItemLabelleft">
            {Title}
            </h3>
        </div>   
        </div>
        <span className='itemDescriptionleft'>
       {Descripcion}
        </span>
    </div>
  )
}

export default Includedleft