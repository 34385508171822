import React, {useState} from 'react';
import { Image } from "react-bootstrap";
import './Gallery.scss';
import GaleryCard1 from '../../Components/GaleryCard1';
import GaleryCard2 from '../../Components/GaleryCard2';
import Ramo3 from "../../assets/jpg/Slide.jpeg";
import Ninja from "../../assets/jpg/Ninja.jpeg";
import WIPEOUTS from "../../assets/jpg/Wipeouts.jpeg";
import Dragon from "../../assets/jpg/Dragon.jpeg";
import Combat from "../../assets/jpg/Combat.jpeg";
import Baby from "../../assets/jpg/Baby.jpeg";
import Gladiator from "../../assets/jpg/Gladiator.jpeg";
import Rapel from "../../assets/jpg/Rapel.jpeg";
import FREEFALL from "../../assets/jpg/FREEFALL.jpeg";
import TRAMPOLINE from "../../assets/jpg/TRAMPOLINE.jpeg";
import WAVES from "../../assets/jpg/WAVES.jpeg";
import Movie from "../../assets/jpg/Movie.jpeg";
import BASCKET from "../../assets/jpg/BASCKET.jpeg";
import CRAZY from "../../assets/jpg/CRAZY.jpeg";
import ELASTIC from "../../assets/jpg/ELASTIC.jpeg";
import Jelly from "../../assets/jpg/Jelly.jpeg";
import {ReactComponent as Cerrar} from "../../assets/svg/close.svg";
import {ReactComponent as Izquierda} from "../../assets/svg/angulo-izquierdo.svg";
import {ReactComponent as Derecha} from "../../assets/svg/angulo-derecho.svg";


const Gallery = () => {
   
    const images = [
        { id: 1, src: Ramo3, text: 'SLIDES GIGANTES' },
        { id: 2, src: Ninja, text: 'NINJA WALL' },
        { id: 3, src: WIPEOUTS, text: 'WIPEOUTS GIGANTES'},
        { id: 4, src: Dragon, text: 'DRAGON WAVES'},
        { id: 5, src: Combat, text: 'COMBAT ZONE'},
        { id: 6, src: Baby, text: 'BABY ZONE INFLATABLE'},
        { id: 7, src: Gladiator, text: 'GLADIATOR ZONE'},
        { id: 8, src: Rapel, text: 'RAPEL ZONE'},
        { id: 9, src: FREEFALL, text: 'FREEFALL INFLATABLE'},
        { id: 10, src: TRAMPOLINE, text: 'TRAMPOLINE INFLATABLE'},
        { id: 11, src: WAVES, text: 'WAVES ZONE'},
        { id: 12, src: Movie, text: 'MOVIE JUMP'},
        { id: 13, src: BASCKET, text: 'BASKET - SOCCER STADIUM'},
        { id: 14, src: CRAZY, text: 'CRAZY BALANCE'},
        { id: 15, src: ELASTIC, text: 'ELASTIC WARRIOR'},
        { id: 16, src: Jelly, text: 'JELLY MOUNTAIN'},
    ];
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

   
    const handleClick = (image) => {
        setSelectedImage(image);
        setShowOverlay(true);
    };
    const getCardComponent = (index) => {
        return index % 2 === 0 ? GaleryCard2 : GaleryCard1;
    };

    const handleLeftClick = () => {
        setSelectedImage(prevImage => {
            const index = images.findIndex(img => img.id === prevImage.id);
            const newIndex = index === 0 ? images.length - 1 : index - 1;
            return images[newIndex];
        });
    };
    
    const handleRightClick = () => {
        setSelectedImage(prevImage => {
            const index = images.findIndex(img => img.id === prevImage.id);
            const newIndex = index === images.length - 1 ? 0 : index + 1;
            return images[newIndex];
        });
    };
  return (
    <>
       {showOverlay && (
                <div className="overlay">
                   <div className="buttonClose" onClick={() => setShowOverlay(false)}>
                    <Cerrar />
                    </div>
                    <div className="slideOverlay">
                        <Izquierda onClick={handleLeftClick}/>
                        <div className="overlayContent">
                            <p>{selectedImage.text}</p>
                            <div className="image" style={{ backgroundImage: `url(${selectedImage.src})` }}></div>
                        </div>  
                         <Derecha onClick={handleRightClick}/>
                    </div>
                    
                </div>
            )} 
     <div className='galleryBlock' id='Modulos'>
        <div className='galleryBlockContent'>
           <div className="galleryBlockTitleWrap marqueeWrap ">
            <div className="galleryBlockTitle marqueeTrack"> 
            <span> MÓDULOS INFLABLES • MÓDULOS INFLABLES • </span>
            <span> MÓDULOS INFLABLES • MÓDULOS INFLABLES • </span>
            <span> MÓDULOS INFLABLES • MÓDULOS INFLABLES • </span>
            <span> MÓDULOS INFLABLES • MÓDULOS INFLABLES • </span>
            <span> MÓDULOS INFLABLES • MÓDULOS INFLABLES • </span>
            <span> MÓDULOS INFLABLES • MÓDULOS INFLABLES • </span>
            <span> MÓDULOS INFLABLES • MÓDULOS INFLABLES • </span>

            </div>
           </div>
           <div className="galleryGridWrap">
            <div className="galleryGrid">
                {/* Seccion 1 */}
                {images.map((card) => {
                            const CardComponent = getCardComponent(card.id);
                            return <CardComponent key={card.id} Image={card.src} Text={card.text} handleClick={handleClick} card={card}/>;
                        })}
            </div>
           </div>
        </div>
    </div>
    </>
   
  )
}

export default Gallery