import React from 'react';
import './Compra.scss';
import TestmonialCard from '../../Components/TestmonialCard/TestmonialCard';
import Apreton from '../../assets/png/apreton.png';
import Diseño from '../../assets/png/diseñoInfla.png';
import Produccion from '../../assets/png/Inflable.png';
import Envio from '../../assets/png/envioInter.png';
import Entrega from '../../assets/png/recepcionEnvio.png';

const Compra = () => {
  return ( 
    <div className='compra_seccion' id='Elaboracion'>
        <div className="testimonialsSectionTitleWrap marqueeWrap">
            <div className="testimonialSectionTitle marqueeTrack">
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            <span> NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • NUESTROS PROCESOS DE ELABORACIÓN Y ENTREGA • </span>
            </div>
        </div>
        <div className="testimonialBlocks">
       <TestmonialCard Image={Apreton} Title={'NEGOCIACIÓN'} Subtitle={'Validamos las propuestas, analizamos tu idea de negocio y brindamos la mejor opción para tu emprendimiento.'}/>
       <TestmonialCard Image={Diseño} Title={'DISEÑO'} Subtitle={'Mediante tu proyecto, elaboramos el diseño de tu emprendimiento con el mejor equipo de diseño, brindándote siempre opciones innovadoras y en tendencia.'}/>
       <TestmonialCard Image={Produccion} Title={'PRODUCCIÓN'} Subtitle={'Una vez aprobada la oferta, procedemos con el proceso de elaboración y producción. Utilizamos los mejores materiales de alta calidad y contamos con un equipo capacitado con años de experiencia en el ramo.'}/>
       <TestmonialCard Image={Envio} Title={'DISTRIBUCIÓN'} Subtitle={'Una vez finalizado el producto, procedemos a realizar el proceso de logística de envío, brindándote seguridad en nuestro proceso de entrega, cumpliendo en tiempo y forma'}/>
       <TestmonialCard Image={Entrega} Title={'ENTREGA'} Subtitle={'Una vez finalizado el proceso de envío, estamos listos para realizar la entrega segura, con la garantía que te mereces y satisfaciendo tus necesidades.'}/>
        </div>
    </div>
  )
}

export default Compra