import React from 'react';
import './GaleryCard1.scss';

const GaleryCard1 = ({Image, Text, handleClick, card}) => {
  return (
    <div className='galleryGridItem animated startAnimation' onClick={() => handleClick(card)}>
         
        <div className="galleryShape">     
            <img src={Image} alt="" />
            <p>{Text}</p>
        </div>    
    </div>
  )
}

export default GaleryCard1