import React from 'react';
import './TestmonialCard.scss';

const TestmonialCard = ({Image, Title, Subtitle}) => {
  return (
    <div className="testimonialBlock">
    <div className="testimonialContent">
      <div className="galleryShape">
       <img className="image" src={Image} alt="Descripción de la imagen"/> 
      </div> 
        <h2 className="includedItemLabelleft">{Title}</h2>
        <span className='itemDescriptionleft'>{Subtitle}</span>      
    </div>
  </div>
  )
} 

export default TestmonialCard