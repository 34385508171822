import React from 'react';

const GaleryCard2 = ({Image, Text, handleClick, card}) => {
  return (
    <div className='galleryGridItem animated startAnimation' onClick={() => handleClick(card)}>
        <div className="galleryShape2">
            <img src={Image} alt="" />
             <p>{Text}</p>
        </div>
    </div>
  )
}

export default GaleryCard2