import React from 'react';
import './Includedright.scss';
import Logo from "../../assets/png/inmagic.png";
 


const Includedright = ({Image, Title, Descripcion}) => {
  return (
    <div className='includedItem'>
        <div className="includedItemHead">
          <div className="itemLogo">
            <img src={Logo} alt="" />
          </div>
         <div className="itemHeadImage oddItem">
            <div className="itemHeadImageWrap">
                <img src={Image} alt="" />
            </div>
            <h3 className="includedItemLabel">
            {Title}
            </h3>
        </div>   
        </div>
        <span className='itemDescription'>
        {Descripcion}
        </span>
    </div>
  )
}

export default Includedright