import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Ramo.scss';
import CardRamo from '../../Components/CardRamo';
import Ramo1 from "../../assets/png/ramo1.png";
import Ramo2 from "../../assets/png/ramo2.png";
import Ramo3 from "../../assets/png/ramo3.png";

const Ramo = () => {
  return (
    <div className='upcomingEventsSection' id='Ventajas'>
        <div className="upcomingEventsSectionTitleWrap marqueeWrap">
            <h2 className='upcomingEventsSectionTitle marqueeTrack'>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>
            <span> ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • ¿POR QUÉ ELEGIR EL RAMO DE INFLABLES? • </span>           
             </h2>
        </div>
        <div className="tourDatesList tourDatesList--onSale wrapperWidth">
<div className="eventsGrid wrapperWidth">
  <Row> 
    <Col md={6} sm={12} xl={4}>
    <CardRamo Image={Ramo1} Title={'INNOVACIÓN Y DISEÑO.'} Text={'Ofrecemos una amplia gama de estilos y diseños para satisfacer diferentes gustos y edades, lo que genera interés en nuestra variedad de productos.'}/>
    </Col>
    <Col md={6} sm={12} xl={4}>
 <CardRamo Image={Ramo2} Title={'SEGURIDAD E HIGIENE.'} Text={'Los materiales utilizados en nuestros inflables no provocan reacciones en la piel, incluso en personas con piel sensible.'}/>
    </Col>
    <Col md={6} sm={12} xl={4}>
  <CardRamo Image={Ramo3} Title={'SALUD.'} Text={'Los inflables ofrecen diversión para niños y adultos, promoviendo la actividad física que mejora la salud y el bienestar, además de ayudar a conciliar el sueño.'}/>
    </Col>
  </Row>
</div>
        </div>
    </div> 
  )
}

export default Ramo