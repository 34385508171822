import React from 'react';
import {Image } from 'react-bootstrap';
import ImageBanner from "../../assets/png/banner.webp";
import './Banner.scss';

const Banner = () => {
  return (
    <section className="banner" id='HomeLugar'>
<div className="animacion">
  <h1>
    <span>BIENVENIDO</span>
    <span>A</span>
    <span>LA</span>
    <span>NUEVA</span>
    <span>ERA</span> 
    <span>INFLABLE</span> 
  </h1>
</div>
<Image src={ImageBanner}/>
    </section>
  

  )
}

export default Banner;