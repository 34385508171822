import React from 'react';
import './Nosotros.scss';
import { Col, Container } from 'react-bootstrap';


const Nosotros = () => {
  return (
    <section className='nosotros' id='Nosotros'>
        <Container fluid>
        <p className='title'>Nosotros</p>
        <div className="introContentDesktopWrap">
         <div className="introDesktopLeftCol">
       
        <p className='introContentDesktop'><span>Somos InMagic</span>, una empresa dedicada al entretenimiento y la planificación de parques inflables. Con una experiencia de 3 años en la industria, ofrecemos productos de alta calidad y excelencia en inflables, Inflatable Party y camas elásticas. </p>  
        <div className="introMainImageDesktopWrap">
         <div className="introMainImageDesktopInner">
          <div className="triangle"></div>
         </div> 
        </div>
        </div>
        <div className="introDesktopRightCol">
          <div className="introDesktopImageOneWrap">
            <div className="introDesktopImageOne">
          <div className="triangle1"></div>
            </div>
          </div>

          <div className="introDesktopImageTwoWrap">
            <div className="introDesktopImageTwo">
          <div className="triangle2"></div>
            </div>
          </div>
        </div> 
        </div>
        <div className="introSectionBlockWrap">
          <div className="introSectionImagesWrap">
            <div className="introSectionAdditionalImageOneWrap">
            <div className="triangle2"></div>
            </div>
            <div className="introSectionMainImageWrap">
          <div className="triangle"></div>
          </div>
          </div>
         
        </div>
        <div className="introSectionTextContentWrap">
          <div className="introSectionTextContent">
          <p><span>Somos InMagic</span>, una empresa dedicada al entretenimiento y la planificación de parques inflables. Con una experiencia de 3 años en la industria, ofrecemos productos de alta calidad y excelencia en inflables, Inflatable Party y camas elásticas. </p>  
          <div className="introSectionAdditionalImageTwoWrap">
          <div className="triangle1"></div>
          </div>
          </div>
        </div>
     
        
   
        </Container>
         </section>
  )
}

export default Nosotros