import React from 'react';
import './CardRamo.scss';

const CardRamo = ({Image, Title, Text}) => {
  return (
    <div className="eventItem"> 
        <div className='eventBgShape'></div>
        <div className="eventItemInner">
            <div className="eventItemImgWrap">
             <img src={Image} alt="" className='imageDesc'/>   
             <h3>{Title}</h3>
             <span>{Text}</span>
            </div>
        </div> 
    </div>
 
    
  )
}

export default CardRamo