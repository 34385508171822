import React from 'react';
import { Link } from "react-scroll";
import {ReactComponent as Facebook} from "../../assets/svg/facebook.svg";
import './Menu.scss';

const Menu = ({menuOpen}) => {
    
  return (
    <div className='menuMeta'>
      <div  className={`flyoutWrap ${menuOpen === true ? 'active' : ''}`}>
        <div className="menuWrapper">
        <ul id="menu-main-menu" className="mainMenu">
      <li> <Link  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-194"
                activeClass="active"
                to="HomeLugar"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Inicio</Link></li> 
           <li> <Link  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-193"
                activeClass="active"
                to="Nosotros"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >nosotros</Link></li> 
          <li> <Link  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-192"
                activeClass="active"
                to="Ventajas"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Ventajas del negocio</Link></li> 
           <li> <Link  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-191"
                activeClass="active"
                to="Clasificaciones"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >clasificaciones</Link></li> 
           
             <li> <Link  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-191"
                activeClass="active"
                to="Modulos"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Módulos</Link></li> 
             <li> <Link  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-191"
                activeClass="active"
                to="Elaboracion"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Elaboración y entrega</Link></li> 
          {/*   <li id="menu-item-194" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-194"><a href="">inicio</a></li> */}
           {/*  <li id="menu-item-193" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-193"><a href="">nosotros</a></li> */}
            {/* <li id="menu-item-192" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-192"><a href="">Ventajas del negocio</a></li> */}
            {/* <li id="menu-item-190" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-190"><a href="">clasificaciones</a></li> */}
           {/*  <li id="menu-item-191" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-191"><a href="">Módulos inflables</a></li>
            <li id="menu-item-1865" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1865"><a href="">Elaboración y entrega</a></li> */}
        </ul>
        </div>
        <div className="socialsMenu">
        <a target="_blank" href="https://www.facebook.com/profile.php?id=61557129922623&is_tour_completed=true" aria-label="Social media icon"><Facebook/></a>
        </div>
        </div>  
    </div>
  )
}

export default Menu