import React from 'react';
import './Proceso.scss';
import Includedleft from '../../Components/Includedleft';
import Includedright from '../../Components/Includedright';
import Parque from "../../assets/png/ramo2.png";
import Inflale from "../../assets/png/InflatableParty.png";
import Elasticas from "../../assets/png/CamasElasticas.png";
import Carpa from "../../assets/png/CarpaInflable.png";



const Proceso = () => {
  return (
    <div className='proceso' id='Clasificaciones'> 
       <div className="eventIncludedSectionTitleWrapProceso marqueeWrap">
        <div className="eventIncludedSectionTitleProceso marqueeTrack">
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        <span> Clasificación de nuestros productos • Clasificación de nuestros productos • </span>
        </div>
     
        <div className="wrapper">
           <div className="includedItems">
            <Includedleft Image={Parque} Title={'Parque inflable'} Descripcion={'Ofrecemos fabricación de parques inflables a medida. Utilizamos materiales de alta calidad y trabajamos contigo para crear diseños únicos y seguros.'}/>
            <Includedright Image={Inflale} Title={'Inflatable Party'} Descripcion={'Podemos crear un gran inflable según tus propias ideas y diseño. Desde castillos mágicos hasta mundos submarinos, tu imaginación es el límite. '}/>
            <Includedleft Image={Elasticas} Title={'camas elásticas'} Descripcion={'Nuestras camas elásticas te ofrecen una experiencia de salto segura y emocionante para todas las edades. Con materiales de alta calidad y diseños robustos.'}/>
            <Includedright Image={Carpa} Title={'Carpa inflable'} Descripcion={'Nuestras carpas inflables son la solución ideal para eventos al aire libre. Se montan en minutos, ofreciendo un espacio protegido y cómodo para tus invitados.'}/>
            </div> 
        </div>
        </div> 
    </div>
  )
}

export default Proceso