import React from 'react';
import {Helmet} from 'react-helmet';
import './Principal.scss';
import Banner from '../Banner';
import Nosotros from '../Nosotros/Nosotros';
import Ramo from '../Ramo/Ramo';
import Gallery from '../Gallery/Gallery';
import Proceso from '../Proceso/Proceso';
import Compra from '../Compra';

export default function Principal() {
    return (
        <div className='principal'>
             <Helmet>
            <title>InMagic - Venta de inflables | inflables personalizados | Fabrica de brincolines | Parques inflables | camas elasticas</title>
            <meta name='description' content='InMagic es tu destino de confianza para la venta de inflables personalizados, brincolines de alta calidad, parques inflables emocionantes y camas elásticas divertidas. Con una amplia variedad de productos diseñados para satisfacer todas tus necesidades de entretenimiento, InMagic se destaca como la opción líder en la industria de los inflables. Descubre nuestra colección hoy y lleva la diversión a un nivel superior con InMagic.'  data-react-helmet="true"/>
            <meta name='og:description' content='InMagic es tu destino de confianza para la venta de inflables personalizados, brincolines de alta calidad, parques inflables emocionantes y camas elásticas divertidas. Con una amplia variedad de productos diseñados para satisfacer todas tus necesidades de entretenimiento, InMagic se destaca como la opción líder en la industria de los inflables. Descubre nuestra colección hoy y lleva la diversión a un nivel superior con InMagic.' data-react-helmet="true"/>
            <meta name='og:title' content="InMagic - Venta de inflables | inflables personalizados | Fabrica de brincolines | Parques inflables | camas elasticas" data-react-helmet="true"/>
            <meta property="og:url" content="https://inflablesinmagic.com.mx/" data-react-helmet="true"/>
            <meta property="og:site_name" content='venta de inflables'  data-react-helmet="true"/>
            <meta property="article:publisher" content="https://www.facebook.com/profile.php?id=61557129922623&is_tour_completed=true" data-react-helmet="true"/>
            <meta name="og:image" content="https://res.cloudinary.com/dhecyqald/image/upload/v1709415465/logo%20inmagic/inmagic_up9gru.jpg" data-react-helmet="true"/>
        
        </Helmet>
            <Banner/>
            <Nosotros/>
            <Ramo/>
            <Proceso/>
            <Gallery/>
            <Compra/>
        </div>
    )
}
